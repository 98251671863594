<template>
  <BaseModal
    :id="'shared'"
    :hideFooter="true"
    :size="'sm'"
    :title="'Compartir carpeta'"
  >
    <div>
      <b-button
        @click="shareHandler('whatsapp')"
        style="padding-top: 4px !important; padding-bottom: 4px !important"
        variant="primary"
        class="btn-block d-flex align-items-center justify-content-center"
      >
        <WhatsappIcon :color="'white'" :w="30" :h="30"></WhatsappIcon>
        <span>
          whatsapp
        </span>
      </b-button>
      <b-button
        @click="shareHandler('email')"
        type="submit"
        variant="primary"
        class="btn-block d-flex align-items-center justify-content-center"
      >
        <feather-icon icon="MailIcon" size="20" class="mr-50"/>
        <span>
          email
        </span>
      </b-button>
      <b-button
        @click="shareHandler('copy')"
        variant="primary"
        class="btn-block d-flex align-items-center  justify-content-center"
      >
        <feather-icon icon="CopyIcon" size="20" class="mr-50"/>
        <span>
          copiar enlace
        </span>
      </b-button>
    </div>
  </BaseModal>
</template>

<script>
import BaseModal from "@/components/ui/modal/BaseModal.vue";
import WhatsappIcon from "@/assets/icons/WhatsappIcon.vue";
import { getIp } from "@/shared/services/ip-service"
import { mapActions } from "vuex";

export default {
  props: {
    folderHash: {
      type: [String, Number],
      required: true,
    },
  },
  components: {
    BaseModal,
    WhatsappIcon,
  },
  methods: {
    ...mapActions({
      share: "folders/share",
    }),
    async shareHandler (type) {
      const ip = await getIp()
      const hash = this.folderHash
      await this.share({ hash, ip, type })

      if (type === 'whatsapp') {
        this.shareOnWhatsApp(hash)
      }
      if (type === 'email') {
        this.shareByEmail(hash)    
      }
      if (type === 'copy') {
        this.copyLink(hash)    
      }
    },
    copyLink(hash) {
      const url = `${window.origin}/carpetas/${hash}`;
      const el = document.createElement("textarea");
      el.value = url
      document.body.appendChild(el);
      el.select();
      navigator.clipboard.writeText(el.value);
      document.body.removeChild(el);
      this.$store.commit("notifications", {
          title: "Enlace copiado.",
          variant: "success",
      });
      this.$store.commit("setShowNotifications", true, { root: true });
    },
    shareOnWhatsApp(hash) {
      const url = `${window.origin}/carpetas/${hash}`;
      console.log(url)
      const whatsappUrl = `https://wa.me/?text=${encodeURIComponent(url)}`;
      window.open(whatsappUrl, "_blank");
    },
    shareByEmail(hash) {
      const url = `${window.origin}/carpetas/${hash}`;
      const subject = "Compartir carpeta GH Induction";
      const body = `${url}`;
      const mailtoUrl = `mailto:?subject=${encodeURIComponent(
        subject
      )}&body=${encodeURIComponent(body)}`;
      window.location.href = mailtoUrl;
    },
  },
};
</script>
