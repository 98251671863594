<template>
  <div>
    <b-card no-body>
      <b-overlay
        :show="loading"
        variant="white"
        spinner-variant="primary"
        rounded="sm"
      >
        <b-row>
          <b-col class="d-flex pr-0" cols="12">
            <span class="logo">
              <b-img :src="appLogoImage" alt="logo" />
            </span>
          </b-col>
          <b-col cols="12" class="pr-0" style="margin-top: -20px">
            <hr />
          </b-col>
        </b-row>
        <b-row class="mx-2 mt-2" align-h="between" align-v="center">
          <b-col class="d-flex"  md="12" lg="4">
            <div class="d-flex">
              <div>
                <FeatherIcon icon="FolderIcon" class="mr-1" size="30" />
              </div>
              <h2>
                {{ folderInfo.name }}
              </h2>
            </div>
          </b-col>
          <b-col md="12" lg="8" class="d-flex justify-content-end pr-0" :class="{ 'pt-2 px-0': ['xs', 'sm'].includes(breakpoint)}">
            <b-button
              @click="$bvModal.show('shared')"
              variant="outline-primary"
              class="btn d-flex justify-content-center align-items-center mr-1"
            >
              <feather-icon icon="Share2Icon" size="20" />
              <span class="ml-50">
                compartir carpeta
              </span>
            </b-button>
            <b-button
              @click="downloadZipAllFiles"
              variant="outline-primary"
              class="btn d-flex justify-content-center align-items-center"
              :disabled="folders && folders.length === 0"
            >
            <span class="mr-50">
              Descargar archivos
            </span>
              <feather-icon icon="DownloadIcon" size="20" />
            </b-button>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" class="my-2">
            <hr />
          </b-col>
        </b-row>
        <b-col
            v-if="folders.length && folders[0].childs.length === 0"
            cols="12"
            class="d-flex justify-content-center"
            style="height: 200px"
          >
            <h3 class="mt-5 text-secondary">
              <FolderOpen
                class="mr-50"
                style="height: 25px; width: 25px"
              ></FolderOpen>
              Carpeta vacia
            </h3>
          </b-col>
          <b-col v-else :class="{ 'p-2 mx-2': !['xs', 'sm'].includes(breakpoint)}">
            <div style="min-height: 150px">
              <TreeFoldersComponent
                v-for="(folder, index) in folders"
                :key="index"
                :folder="folder"
                @downloadFile="downloadFileHandler"
              />
            </div>
          </b-col>
      </b-overlay>
    </b-card>
    <SharedModal :folderHash="folderInfo.hash"></SharedModal>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import TreeFoldersComponent from "@/components/folders/tree/TreeFoldersComponent.vue";
import FolderOpen from "@/assets/icons/FolderOpen.vue";
import { $themeConfig } from "../../../themeConfig.js";
import SharedModal from "@/components/shared/modal/SharedModal.vue";

export default {
  components: {
    FolderOpen,
    TreeFoldersComponent,
    SharedModal,
  },
  data() {
    return {
      folders: [],
      folderInfo: {
        name: "",
        hash: "",
      },
      loading: false,
      appLogoImage: $themeConfig.app.appLogoImage,
    };
  },
  computed: {
    folderId() {
      return this.$route.params.id;
    },
    appName() {
      return $themeConfig.app.appName;
    },
    breakpoint() {
      return this.$store.getters['app/currentBreakPoint']
    }
  },
  methods: {
    ...mapActions({
      getFolder: "folders/getPublicFolder",
      downloadFile: "archives/downloadPublicArchive",
      downloadAllFiles: "archives/downloadPublicArchivesZip",
    }),
    async loadData() {
      const response = await this.getFolder(this.folderId);
      const { name, hash, childs } = response.data[0];
      this.folderInfo = { name, hash };
      this.folders = this.orderArray(childs);
    },
    orderArray(elements) {
      elements.sort((a, b) => a.name.localeCompare(b.name));

      elements.forEach((item) => {
        if (item.childs && item.childs.length > 0) {
          this.orderArray(item.childs);
        }
        if (item.files && item.files.length > 0) {
          this.orderArray(item.files);
        }
      });

      return elements;
    },
    async downloadZipAllFiles(folderId) {
      this.loading = true 
      await this.downloadAllFiles({ name: this.folderInfo.name, folder_id: this.folderId })
      this.loading = false 
    },
    async downloadFileHandler(archive, action) {
      this.loading = true 
      await this.downloadFile({ archive, folder_id: this.folderId, action })
      this.loading = false 
    },
  },
  async mounted() {
    await this.loadData();
  },
};
</script>

<style>
.logo img {
  height: 100px;
  width: 100px;
}
</style>
