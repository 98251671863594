<template>
  <b-modal
    v-model="model"
    :id="id"
    :title="title"
    :size="size"
    centered
    :scrollable="scrollable"
    :lazy="lazy"
    :static="staticModal"
    :hide-footer="hideFooter"
  >
    <b-overlay
      :show="loading"
      variant="white"
      spinner-variant="primary"
      rounded="sm"
    >
      <slot />
    </b-overlay>
    <template #modal-footer>
      <div class="d-flex">
        <b-button
          variant="outline-primary mr-1"
          @click="[$emit('input', false), $bvModal.hide(`${id}`)]"
        >
          {{ cancelButtonText || $t("Cancelar") }}
        </b-button>
        <b-button variant="primary" @click="$emit('confirm-button-click')">
          {{ confirmButtonText || $t("Guardar") }}
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
export default {
  name: "BaseModal",
  props: {
    id: {
      type: String,
      default: "",
    },
    value: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    confirmButtonText: {
      type: String,
      default: null,
    },
    cancelButtonText: {
      type: String,
      default: "",
    },
    size: {
      type: String,
      default: "xl",
    },
    title: {
      type: String,
      default: "",
    },
    scrollable: {
      type: Boolean,
      default: true,
    },
    lazy: {
      type: Boolean,
      default: false,
    },
    staticModal: {
      type: Boolean,
      default: false,
    },
    hideFooter: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
};
</script>

<style scoped></style>
